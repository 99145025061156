import { render, staticRenderFns } from "./WcgTicketSupplyItem.vue?vue&type=template&id=3a437123&"
import script from "./WcgTicketSupplyItem.vue?vue&type=script&lang=js&"
export * from "./WcgTicketSupplyItem.vue?vue&type=script&lang=js&"
import style0 from "./WcgTicketSupplyItem.vue?vue&type=style&index=0&id=3a437123&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports