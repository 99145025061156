<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t(['_', reason, 'title']) }}</span>
    </template>
    <p class="main-msg">{{ $t(['_', reason, 'msg1'], { ...message.data.notice, rank }) }}</p>
    <p class="sub-msg">{{ $t(['_', reason, 'msg2']) }}</p>
  </notification-item>
</template>

<script>
import NotificationItem from '@shared/components/notification/NotificationItem.vue';
import Specific from '@shared/types/Specific';
import { values } from '@shared/utils/commonUtils';

export default {
  name: 'WcgPointSupplyItem',
  lexicon: 'shared.notification.LAND_TICKET_SUPPLY',
  components: { NotificationItem },
  props: {
    message: Specific,
  },
  computed: {
    reason() {
      if (this.message.data.notice.reasonType in values('WCG_ARENA_APPLY', 'WCG_ARENA_CHECK_IN')) return this.message.data.notice.reasonType;
      return 'WIN';
    },
    rank() {
      return this.message.data.notice.reasonType.match(/\d+/)?.[0];
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>
